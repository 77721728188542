@import url('https://fonts.googleapis.com/css2?family=Changa+One:ital@0;1&family=Monoton&family=Nanum+Gothic+Coding:wght@700&display=swap');

/* Dark mode palette
000000,1a1a1a,cccccc,ffffff,a2c4bd,88ebd2
#000000,#1a1a1a,#cccccc,#ffffff,#a2c4bd,#88ebd2 */

:root {
  --bg: #000;
  --bg-highlight: #222;
  --edges: #cccccc;
  --text: #fff;
  --high-nofocus: #a2c4bd;
  --high: #88edb2;
  --curve: 0.5em;
  --border: 2px solid var(--high);
}

body {
  background: var(--bg);
}

.page {
  min-height: 77vh;
}

.center {
  display: flex;
  justify-content: space-evenly;
}

/* //////////// TEXT \\\\\\\\\\\\\\\\\ */

.brand-title {
  font-family: Monoton;
  color: var(--bg);
  background-color: var(--text);
}

.brand-title {
  font-size: 256pt;
  padding: 1%;
  margin-bottom: 2%;
  background: -webkit-linear-gradient(245deg, var(--text), var(--high));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

a,
p,
h1,
h2,
h3,
h4,
h5 {
  color: var(--text);
}

span {
  /* background-color: var(--bg-highlight); */
}

/* //////// INDIVIDUAL INLINE ELEMENTS \\\\\\\ */
.white-text {
  color: var(--text) !important;
}

.grey-text {
  color: var(--edges) !important;
}

.background-grey {
  background-color: var(--bg-highlight) !important;
}

.background-black {
  background-color: var(--bg) !important;
}

.border {
  border: var(--border);
  border-radius: var(--curve);
  z-index: 3;
}

.borderGrey {
  border: 2px solid var(--edges);
  border-radius: var(--curve);
  z-index: 3;
}


/* /////////////////////////////////////////// */

input,
select,
textarea {
  background: none !important;
  border: 1px solid var(--edges) !important;
  border-radius: 0.5em !important;
  color: var(--text) !important;
  z-index: 2 !important;
}

form {
  z-index: 2 !important
}

select {
  border-right: var(--border) !important;
  background: var(--bg-highlight) !important;
}

input:active {
  border: 2px solid var(--edges) !important;
  border-radius: var(--curve) !important;
}

/* Navigation bar styling */
.navigationBar {
  background-color: var(--bg-highlight) !important;
  color: var(--high-nofocus) !important;
  border-bottom: 1px solid var(--high);
}

.navigationLogo {
  font-family: Monoton;
  color: var(--high) !important;
  background-color: var(--bg-highlight) !important;
  font-size: 2.25em;
}

Footer {
  background-color: var(--bg-highlight) !important;
  border-top: 1px solid var(--high);
  margin-top: 2em;
}

a.block {
  color: var(--high-nofocus);
  font-size: 1.5em !important;
  padding: 5px;
  border-radius: 0.25em;
  transition: 0.25s ease;
}

li:hover,
a.block:hover,
.playlistItem:hover {
  color: var(--high) !important;
  cursor: pointer;
  background-color: rgba(162, 196, 189, .33) !important;
  transform: scale(1.025) !important;
  transition-duration: 0.1s;
  transition-timing-function: ease-in;

}

/* Navigation bar Dropdown item styling */
.dropdownItem,
.dropdownItem2 {
  color: var(--high) !important;
  font-size: 1.5em !important;
}

.dropdownItem2 {
  background-color: var(--bg-highlight) !important;
  color: var(--edges) !important;
}

div.z-10 {
  background-color: var(--bg-highlight);
}

.userNameSurname,
.userEmail {
  color: var(--high-nofocus);
  font-size: 1.5em;
}

span.flex {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-right: 0.75em;
  padding-left: 0.75em;
  background-color: var(--high-nofocus);
  color: var(--bg);
  transform: all 0.25s ease;
}

span.flex:hover {
  background-color: var(--high);
}

#searchinput {
  margin: 0;
}

.userImage {
  height: 18vh;
  min-width: 12vw;
  object-fit: cover;
}

.adminBadge {
  background-color: var(--bg) !important;
  color: var(--edges) !important;
}

button {
  background-color: var(--high-nofocus) !important;
  z-index: 5 !important;
}

button:hover {
  background-color: var(--high) !important;
  color: var(--bg) !important;
}

.resultsCard::after {
  border-top-left-radius: var(--curve);
  border-top-right-radius: var(--curve);
  object-fit: cover;
  aspect-ratio: 1;
  box-sizing: unset !important;

}

.innerText {
  background-color: var(--bg-highlight);
  border-top: 1px solid var(--high);
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 200px;
}

.cardTitle {
  color: var(--text) !important;
}

div {
  box-sizing: content-box !important;
}

.hoverbuttons {
  position: fixed;
  right: 1%;
  z-index: 99;
}

.hoverhover:hover {
  filter: invert();
  transform: scale(1.5)
}

.hover1 {
  bottom: 65%;
}

.hover2 {
  bottom: 50%;
}

.hover3 {
  bottom: 35%;
}

#sidebar {
  margin: 1%;
  border-radius: 1em;
  background-color: var(--bg-highlight);
}

/* ///////// ARTISTS PAGE /////////////////*/

.artistHeading {
  font-size: 3em;
  font-weight: 600;
}

.artistTitle {
  font-size: 5em;
  font-weight: 600;
}

.artistImage {
  transition: all 0.25s ease;
}

.artistImage:hover {
  transform: scale(1.6);
  filter: saturate(1.2);
  z-index: 2;
}

.albumHeading {
  color: var(--high);
  font-size: 1.5em;
  font-weight: 600;
}

.artistText {
  color: var(--text);
  margin-right: 3em;
}

.discographyTable {
  margin-right: 6em;
}

.modal {
  background-color: var(--bg-highlight);
  color: var(--text);
  padding: 10px;
  position: fixed;
  top: 25%;
  right: 18%;
  max-width: 14%;
  z-index: 100;
}


.albumSong {
  color: black
}

/* ///////// TIMELINE /////////////////*/
.timelineContainer {
  margin-right: 5em !important;
  margin-left: 2.5% !important;
}

*,
::after,
::before {
  --tw-ring-offset-width: 0;
}

.commentArea {
  display: flex;
  background-color: var(--bg-highlight);
  color: var(--text) !important;
  margin: 1em;
}

.replyArea {
  display: none;
  background-color: var(--bg-highlight);
  color: var(--text) !important;
}

.postCardImage {
  height: 150px !important;
}

.timestamp {
  color: var(--text) !important;
}

.timestamp:hover{
  background:none !important;
  background-color: none !important;
}

.accordionElement {
  width: 90vw !important;
}

.accordionTitle {
  padding: none !important;
}

.accordionTop {
  background-color: var(--bg) !important;
}

.accordionTop:hover {
  background-color: var(--bg-highlight) !important;
}

.accordionContent {
  background-color: var(--bg);
}

.postBtn {
  margin-top: 1em;
}

/* ///////// RADIO /////////////////*/
.radio {
  background-color: var(--bg) !important;
}

.radio:checked {
  background-color: var(--high) !important;
}

.radio:active,
.radio:focus {
  border: var(--border) !important;
}

/* ///////// ADD TRACK MODAL ///////// */
.hamburger {
  cursor: pointer;
}

.hamburger:hover,
.hamburger:active {
  transform: scale(1.25);
}

.trackModal {
  background-color: var(--bg-highlight);
  color: var(--text);
  padding: 10px;
  position: fixed;
  left: 18%;
  top: 20%;
  min-width: 20%;
  z-index: 100;
}

.playlistTitle {
  font-size: 3em;
  text-align: center;
}

.heartIcon {
  padding-left: 1.5em;
}

/* .bg-white {
  --tw-bg-opacity: 1;
  background-color: var(--edges);
} */

.playlistDark {
  background-color: var(--bg-highlight);
}

.playlistBlack {
  background-color: var(--bg);
  color: var(--edges) !important;
}

.playlistBlack:hover {
  background-color: var(--high-nofocus);
  color: var(--bg) !important;
}